@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: url(#section-divider-clippath);
  -webkit-clip-path: url(#section-divider-clippath);

  @media (--viewportMedium) {
    min-height: 65vh;
  }
}

.sectionDividerContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.sectionDividerBlock {
  position: absolute;
  height: 6vw;
  bottom: 0;
  left: 0;
  width: 100%; 
  visibility: 100%;
}

.sectionDividerClip {
  display: block;
  position: absolute;
  top: 0;
  left: 0; 
  width: 100%;
  height: 100%; 
  overflow: visible;
}

.sectionDividerClippathHalfmoon{
  visibility: visible;
  @media (--viewportMedium) {
    visibility: hidden;
  }
}

.sectionDividerClippathHalfmoonTwo{
  visibility: hidden;

  @media (--viewportMedium) {
    visibility: visible;
  }
}

